import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { NewFAQ } from "../components/sections/faq";
import { faq } from "../utils/seoText";

import { Footer } from "../components/sections/footer";

export const FAQPage = () => {
  return (
    <Layout>
      <SEO {...faq} />
      <Navigation />
      <NewFAQ />
      <Footer />
    </Layout>
  );
};

export default FAQPage;
