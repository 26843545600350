import React, { useState } from "react";

import { Transition } from "@headlessui/react";

const questions: { question: string; response: JSX.Element }[] = [
  {
    question: "What is the science behind Zorbi?",
    response: (
      <>
        Zorbi makes use of two study techniques which are backed by a century of
        scientific research: <em>active recall</em> and{" "}
        <em>spaced repetition</em>.
        <br />
        <br />
        Active recall is the process of <em>actively</em> remembering
        information in an effort to strengthen memory connections.
        <br />
        <br />
        Spaced repetition is the process of performing active recall several
        times over an extended period. 
      </>
    ),
  },
  {
    question: "Is my data safe with Zorbi?",
    response: (
      <>
        Yes. You will always have ownership of your data.
        <br />
        <br />
        We're building robust export and backup systems for our users. You'll be
        able to export all data in several formats that other tools will be able
        to use.
      </>
    ),
  },
  {
    question: "Why did you make Zorbi?",
    response: (
      <>
        We came across spaced-repetition tools while studying at university. The
        benefits became clear immediately, we were studying significantly less
        while getting top grades.
        <br /> <br />
        It turned out that not many people knew about how effective these
        techniques were, and even less people were using the techniques. We
        found that most people weren't able to adopt the techniques because of
        issues with existing tools.
        <br />
        <br />
        After more research, a few of us realised the impact we could make if we
        solved the issues preventing the widespread adoption of
        spaced-repetition. We wrote a research paper on how we can increase the
        adoption of spaced-repetition tools and the result of those learnings is
        Zorbi.
        <br />
        <br />
      </>
    ),
  },
  {
    question: "How can I trust that Zorbi won't shut down like _________?",
    response: (
      <>
        Our mission is to help <em>make memory a choice</em> for one million
        people. We're committed to this and have no plans of shutting down
        Zorbi.
        <br />
        <br />
        In the case Zorbi does ever shut down, we will create an automated
        system that sends you your data in multiple formats, along with
        instructions for importing it into other tools.
        <br />
      </>
    ),
  },
  {
    question: "Does Zorbi have a mobile app?",
    response: (
      <>
        We have{" "}
        <a
          target="_blank"
          className="underline"
          href="https://play.google.com/store/apps/details?id=com.zorbi.zorbi"
        >
          an Android app available here
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          className="underline"
          href="https://apps.apple.com/nz/app/zorbi-flashcards/id1586933814"
        >
          an iOS app available here.
        </a>{" "}
      </>
    ),
  },
  {
    question: "Can I use the Chrome extension on a PDF?",
    response: (
      <>
        Yes, the extension works natively on PDFs. If you want to use the
        extension on a local PDF then you{" "}
        <a
          target="_blank"
          className="underline"
          href="https://zorbi.app/pdf_viewer"
        >
          need to use our PDF Viewer here
        </a>
        .
      </>
    ),
  },
  {
    question: "Can you delete my data from your systems?",
    response: (
      <>
        Yes, You can request for your data to be completely purged from our
        systems by reaching out to us at{" "}
        <a className="underline" href="mailto:contact@zorbiapp.com">
          contact@zorbiapp.com
        </a>
        .
      </>
    ),
  },
];

const classes = {
  container: `mx-auto mb-16`,
  questionWrapper: `divide-y divide-gray-300 max-w-4xl mx-auto pt-8`,
  question: `px-4 py-4`,
  questionTitle: `text-xl font-bold text-gray-700`,
  questionBodyCollapsed: `hidden`,
  questionBodyExpanded: `p-4`,
};

const QuestionWrapper = ({ children }) => (
  <div className={classes.questionWrapper}>{children}</div>
);

const Question: React.FC<{ question: string }> = ({ question, children }) => {
  const [isShown, setShown] = useState(false);
  return (
    <div
      x-data="{ show: false }"
      className="relative overflow-hidden border-b border-gray-100 select-none"
    >
      <h4
        onClick={() => setShown(!isShown)}
        className="flex items-center justify-between px-2 py-6 text-lg font-medium text-gray-700 cursor-pointer sm:text-xl md:text-2xl hover:text-gray-900"
      >
        <span>{question}</span>
        <svg
          className={`w-6 h-6 mr-2 transition-all duration-200 ease-out transform ${
            isShown ? "-rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </h4>
      <Transition
        show={isShown}
        enter="transition-all ease-out duration-300"
        enterFrom="opacity-0 transform -translate-y-4"
        enterTo="opacity-100 transform -translate-y-0"
        leave="transition-all ease-in duration-300"
        leaveFrom="opacity-100 transform -translate-y-0"
        leaveTo="opacity-0 transform -translate-y-4"
      >
        <p className="px-2 pt-0 -mt-2 leading-7 text-gray-400 sm:text-lg py-7">
          {children}
        </p>
      </Transition>
    </div>
  );
};

export const NewFAQ = () => (
  <section className="py-16 md:py-20 lg:py-24">
    <div className="max-w-5xl px-12 mx-auto xl:px-0">
      <h2 className="text-3xl font-black md:text-4xl lg:text-6xl xl:text-7xl">
        Frequently Asked Questions
      </h2>
      <p className="mt-4 text-xl font-thin text-gray-700 lg:text-2xl">
        Here are some of the most common frequently asked questions
      </p>
      <p className="mt-2 text-sm leading-loose md:text-base text-blueGray-500">
        <span>
          Can&rsquo;t find the answer you&rsquo;re looking for? Ask us at
        </span>{" "}
        <a
          className="text-blue-600 hover:text-blue-700"
          href="mailto:contact@zorbiapp.com"
        >
          contact@zorbiapp.com
        </a>
      </p>

      <div className="relative mt-12 space-y-4">
        {questions.map(({ question, response: answer }) => (
          <Question question={question}>{answer}</Question>
        ))}
      </div>
    </div>
  </section>
);

export default NewFAQ;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//     },
//     heading: {
//       fontSize: theme.typography.pxToRem(15),
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//   })
// );
// export const NewFAQ1 = () => {
//   const classes = useStyles();
//   return (
//     <div>
//       <section className="py-20 bg-top bg-no-repeat lg:bg-contain">
//         <div className="container px-4 mx-auto">
//           <div className="max-w-sm mx-auto mb-12 text-center">
//             <h2 className="mb-4 text-3xl font-bold md:text-4xl font-heading">
//               Frequently Asked Questions
//             </h2>
//             <p className="text-sm leading-loose md:text-base text-blueGray-500">
//               <span>
//                 Can&rsquo;t find the answer you&rsquo;re looking for? Ask us at
//               </span>{" "}
//               <a
//                 className="text-blue-600 hover:text-blue-700"
//                 href="mailto:contact@zorbiapp.com"
//               >
//                 contact@zorbiapp.com
//               </a>
//             </p>
//           </div>
//           <div className="max-w-3xl mx-auto">
//             <ul className="space-y-4">
//               {questions.map((q) => (
//                 <Accordion key={q.question}>
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon className="text-purple-600" />}
//                     aria-controls="panel1a-content"
//                     id="panel1a-header"
//                   >
//                     <span className="text-base font-semibold font-heading">
//                       {" "}
//                       {q.question}
//                     </span>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <Typography>{q.response}</Typography>
//                   </AccordionDetails>
//                 </Accordion>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };
